/* preloading */

.loadings{
    -webkit-animation: sk-scaleout 3s infinite ease-in-out;
    animation:sk-scaleout 3s infinite ease-in-out; 
    position: relative;
    top: 0;
    width:0px;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    overflow: hidden;
    border-top:2px solid red;
    background: transparent;
    padding-top:20px;
   
  }
  @keyframes sk-scaleout {
    0%{
      width: 0px;
    }
    100%{
     width: 100%;
    }
}

  .dotted{
    -webkit-animation: sk-scaleout 3s infinite ease-in-out;
    animation:sk-scaleout 3s infinite ease-in-out; 
    border:3px solid #e61929;
    /* margin-top: 20px; */
  }
  .loading .imgssss{
   background-image: url("../img/logo.png");
   width: 100px;
   height:90px;
   background-size: contain;
   background-repeat: no-repeat;
  }
  .spinnerss{
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }
  @keyframes sk-scaleout {
      0%{
        width: 0px;
      }
      100%{
       width: 100%;
      }
  }
  .Paginationss{
    padding: 20px 0px;
  }

  .messs{
    width:40vw;
    height:40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px #000;
  }

  .success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.section-1:nth-child(2), .section-1:nth-child(4),.section-1:nth-child(6),.section-1:nth-child(8) {
    transform: rotate(180deg);
}
.section-1:nth-child(2) .imgss, .section-1:nth-child(2) .story-details, .section-1:nth-child(4) .imgss, .section-1:nth-child(4) .story-details, .section-1:nth-child(6) .imgss, .section-1:nth-child(6) .story-details {
    transform: rotate(-180deg);
}
.blogpage{
    width: 80%;
    margin: auto;
}
.blogsimage{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% auto;
}
.blogsimage img{
   width: 100%;
}
.blogsauth{
    width: 100%;
    text-align: center;
}
.blogsauth span{
    margin:0px 10px;
    text-transform: capitalize;
}
.blogsauth span i{
    margin-right: 10px;
}
.blogsauth h1{
    font-size:30px;
    margin-bottom: 10px;
   
}
.blogsdescription{
    width: 100%;
  margin: 2% 0%;
}
.blogsdescription p{
    text-align: justify;
}
.errors{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.errors div{
    text-align: center;

}
.errors div img{
    width: 100px;
}
.errors div h3{
    margin-top: 10px;
    color:#e61929;
}