@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    padding: 0;
    margin: 0;
}
html,body{
 width: 100%;
 height: 100%;
 font-family: "Poppins";
  /* font-weight: 100; */
  font-style: normal;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
a{
    text-decoration: none;
    color: inherit;
}
:root{
    --MColor: #1a9acf;
    --SColor: #9d2430;
    --red: #9d2430;
    --WColor: #fff;
    --Hcolor:#ddd;
    --HScolor:#ebebeb;
    --blue-linear: linear-gradient(135deg, #1A9ACF 0%, #A5E5FF 100%);
    
}
.container{
    padding: 0px!important;
   
}
/* @media (max-width:2560px){
    .container{
       max-width: 1825px;
       width: 100%;
       
    }
}
@media (max-width:1440px){
    .container{
       max-width:1320px;
       width: 100%;
       
    }
} */

.TopNav{
    background-color: var(--MColor);
    color: var(--WColor);
    height:35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 2em;
    border-bottom: 2px solid var(--SColor);
}

.TopItems{
    display: flex;
    align-items: center;
    gap:20px;
    margin: 0;
    padding: 0;
}
.TopItems li{
    list-style: none;
} 
.TopItems li:hover{
    color: var(--Hcolor);
}
.AR{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}
.AL{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}


.MainLogo img{
    width: 100px;
    padding: 10px 0px;
}
.MainLogo span{
    font-size:20px;
    text-transform: uppercase;
    color: var(--SColor);
    font-weight: bold;
   letter-spacing: 2px;
}
.SearchForm input{
    border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.SearchForm input,.SearchForm button{
    border:1px solid var(--MColor);
   padding: 5px 10px;
  
}
.SearchForm input:focus{
    outline: 0px;
}
.SearchForm button{
    background-color: var(--MColor);
    color: var(--WColor);
}
.navbar{
    background-color: var(--SColor);
    padding: 0px;
}
.navbar-nav .nav-item .nav-link{
    color: var(--WColor);
    padding: 8px 25px 8px 0px!important;
    font-size:0.9rem;
    text-transform: uppercase;
}

.MainBanner{
    height:450px;
    overflow: hidden;
}
.carousel-root{
    height: 100%;
}
.slider-wrapper{
    height: 100%;
}
.slide{
    height: 100%;
}

.slider{
    height: 100%;
}
.carousel{
    height: 100%;
}
.slide div{
    height: 100%;
}
.slide div img{
    height: 100%;
    object-fit: cover;
}
.MainTrending{
    background:linear-gradient(to right, rgba(26,154,207,0.2),rgba(26,154,207,0.1));
    min-height:65vh;
    height: 100%;
}
.TTitle{
    font-size: 40px;
    font-weight: 800;
    color:var(--red);
    padding: 20px 0px 30px 0px;
}
.TItemsBox{
    background-color: #fff;
    width: 90%;
    margin:auto;
    border-radius: 5px;
    overflow: hidden;
}
.TItemsBox:hover{
    cursor: pointer;
}
.CIMG{
    width: 50%;
    height: 200px;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    margin: auto;
}
.CIMG img{
    width: 100%;
    object-fit: cover;
}
.CTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--SColor);
    color: var(--WColor);
    min-height: 58px;
}
.CTitle h2{
    font-size: 0.8rem;
    padding: 0px 5px;
    margin-bottom: 0px;
}
.CompanyIntro{
    padding: 50px 0px;
}
.CImages{
    height:600px;
    overflow: hidden;
}
.CImages img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.CContent{
    margin-top: 100px;
}
.CContent h1{
    color: var(--SColor);
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 30px;
}
.CContent p{
    font-size: 1rem;
    line-height: 28px;
    text-align: justify;

}
.CContent p span{
    color: var(--SColor);
}
.post__content{
    margin-bottom: 20px;
}
.BTNSSS{
    border:1px solid var(--SColor);
    background-color:var(--SColor);
    color: var(--WColor);
    padding: 10px 20px;
    border-radius:20px;
}
.BTNSSS:hover{
    background-color:#c83d4b;
}
.slick-slider{
    position: relative;
}
.slick-dots{
   position: absolute;
   top: -80px;
   text-align: right;
}
.slick-dots li button:before{
    color: var(--SColor);
}

.MainServices{
    background-color:var(--HScolor);
}
.STitle{
    text-align: center;
    padding: 50px 0px;
}   
.STitle h1{
    color: var(--SColor);
    font-size: 40px;
    font-weight: 800;
}
.STitle p span{
    color: var(--SColor);
}
.SBox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.SBox div{
    border:1px solid var(--SColor);
    padding:20px 5px;
    margin-bottom:50px;
    background-color: var(--SColor);
    text-align: center;
    color: var(--WColor);
    min-height: 290px;
}
.SBox div img{
    margin-bottom: 10px;
    width: 100px;
}
.servicesss div:nth-child(2) .SBox div{
    background-color:var(--MColor);
    border-color: var(--MColor);
}
.CHColor div{
    background-color:var(--MColor);
    border-color: var(--MColor);
}

.SBox div i{
    font-size: 50px;
    margin-bottom: 20px;
}
.SBox div h2{
    font-size: 25px;
    margin-bottom: 10px;
}
.BItems{
   min-height: 200px;
}
.BIMGS{
    width:90%;
    margin:10px auto;
    height: 120px;
    overflow: hidden;   
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid var(--SColor);
    border-radius: 5px;
}
.BIMGS img{
    width: 100%;
   
}
.BestSeller{
    background-color: var(--HScolor);
    padding: 50px 0px;
}
.sumury{
    padding: 10px;
}
.sumury h1{
    color: var(--SColor);
    font-size: 40px;
    font-weight: 800;
}
.sumury p{
  font-size: 16px;
  letter-spacing: 1px;
}

.MainContact{
    background-color: var(--HScolor);
}

.ContactBox{
    padding:50px 20px;
    
}
.ContactBox h1{
    color: var(--SColor);
    font-size: 40px;
    font-weight: 800;
    margin-bottom:20px;
}
.ContactBox p{
    color:var(--SColor);
    margin-bottom: 20px;
}
.ContactBox p i{
    font-size: 25px;
    padding-right: 1rem;

}
.contMap{
    padding: 50px 20px; 
}
.contMap iframe{
    width: 100%;
    height: 300px;
}


.MainFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    background-color: var(--MColor);
}
.FootLogo{
    color: var(--WColor);
}
.FootLogo img{
    width:150px;
    
}
.FootLogo h2{
    font-size: 20px;
    color: var(--WColor);
}
.FootMenu h2{
    font-size: 20px;
    color: var(--WColor);
    margin-bottom: 20px;
}
.FootMenu ul {
    margin: 0;
    padding: 0;
}
.FootMenu ul li{
    list-style: none;
    color: var(--WColor);
    margin-bottom: 10px;
}
.FootMenu{
    margin-top:30px;
}
.FootMenu ul li i{
    margin-right: 10px;
}
.newslatter{
    margin-bottom: 10px;
}
.newslatter form input{
    border:1px solid #ddd;
    padding: 5px 10px;
    width: 80%;

}
.newslatter form input:focus{
    outline: 0px;
}
.newslatter form  button{
    border:1px solid var(--red);
    padding: 5px;
    background-color: var(--red);
    color: var(--WColor);
    padding: 5px 10px;
    text-transform: uppercase;
}
.follow{
    display: flex;
    margin-top:30px;
   gap: 10px;
}
.follow h2{
    margin-bottom:10px;
}
.follow ul li{
    list-style: none;
    display: inline-block;
    margin-right: 10px;
}
.follow ul li i{
    font-size: 20px;
}
.COpyRights{
    text-align: center;
    background-color: var(--SColor);
    color: var(--WColor);
    padding: 10px 0px;
    font-size: 14px;
    margin: 0px;
}   
.Breadcom{
    padding: 10px;
   
}
.Breadcom ul{
    margin: 0;
    padding: 0;
    display: flex;
    gap: 10px;
}
.Breadcom ul li{
    list-style: none; 
    position: relative;
    margin-right: 5px;
    padding-right: 5px;
}
.Breadcom ul li:first-child::after{
    content: ">";
    position: absolute;
    top:0;
    right: -10px;
}
.Breadcom ul li:nth-child(2)::after{
    content: ">";
    position: absolute;
    top:0;
    right: -10px;
}
.Breadcom ul li:nth-child(3){
    color:var(--SColor);
}
.Breadcom h1{
    font-size: 25px;
    color: var(--SColor);
    text-transform: capitalize;
   
}

.ProductsDetails{
    padding: 20px;
}
.FilterNav{
    border:1px solid var(--red);
    height: 500px;
}
.FilterNav h3{
    background-color: var(--SColor);
    color: var(--WColor);
    padding: 10px;
    margin: 0px;
    font-size:20px;
    
}
.ProductsItems{
    border:1px solid var(--SColor);
    height: 500px;
}
.breadcrumsss{
    padding: 20px;
    background-image: linear-gradient(0deg,#fff,#eee);
}
.Breds{
   
}
.Breds span{
    position: relative;
    padding: 0px 10px 0px 10px;
}
.Breds span:nth-child(2){
    padding-left: 10px;
}
.Breds span:last-child{
    padding-left: 10px;
}
.Breds span:first-child::after{
    content: "/";
    position: absolute;
    margin-left: 10px;
    
}

.Breds span:nth-child(2)::after{
    content: "/";
    position: absolute;
    margin-left: 10px;
}
.Breds span:nth-child(3)::after{
    content: "/";
    position: absolute;
    margin-left: 10px;
}
.Breds span:nth-child(4)::after{
    content: "/";
    position: absolute;
    margin-left: 10px;
}
.Breds span:last-child::after{
    content: "";
    position: absolute;
    margin-left: 10px;
    
}
.Breds span:last-child{
    color: var(--red);
}

.PageTitle{
    padding: 5px 0px;
    text-transform: uppercase;
    font-size: 30px;
    color: var(--red);
    letter-spacing: 1.5px;
    position: relative;
    font-weight: bold;
    text-shadow:1px 1px 2px var(--MColor);
}
.about-section{
    margin-bottom: 20px;
}
/* .insideContent{
    padding: 20px;
} */
.insideContent h1{
    color: var(--red);
    font-size: 40px;
    font-weight: 700;
}
.insideContent p div{
    font-size:18px;
}
.insideTitle{
    color: var(--red);
    font-size: 30px;
}
.aboutImage{
    padding-bottom: 20px;
}
.aboutImage img{
    width: 100%;
    z-index: 99;
    position: relative;
}

.aboutImage::after{
    content: '';
    position: absolute;
    right: 0px;
    width: 350px;
    height:69vh;
    background-color:rgba(26,154,207,0.5);
    z-index: 0;
}

.specification .react-tabs{
    padding: 20px;
}

.TabImagess .imgss{
    width: 90%;
    margin: auto;
    height: 140px;
    margin-bottom: 20px;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px #ddd;
    overflow: hidden;
    border-radius: 5px;
}
.TabImagess .imgss img{
    width: 100%;
    height: 100%;
}
.ProductsImage{
    border: 1px solid #ddd;
    padding: 10px;
}
.BrandImage,.Products_details{
    padding:0px 30px;
}
.BrandImage{
    margin-bottom: 10px;
}
.BrandImage h1{
    font-size: 20px;
    margin-bottom: 10px;
}
.BrandImage h1 span{
    color: var(--red);
}
.BrandImage img{
    width: 100px!important;
}
.specification {
    padding: 30px 0px;
}
.specification div table{
    width: 100%!important;
}
.Products_details h1{
    font-size: 20px;
    border-bottom: 1px solid var(--HScolor);
    padding-bottom: 10px;
    width: 95%;
}
.Products_details h1 span{
    font-size: 18px;
    color: var(--red);
}

.Products_details div ul{
    margin-left: 30px!important;
}
.Products_details div ul li{
    font-size: 15px!important;
    margin-bottom: 10px;
}
.specification h1{
    font-size: 20px;
}

.specification div table tbody tr:first-child td{
    background: rgb(189,17,18)!important;
    color: var(--Hcolor)!important;
}
.specification div table tbody tr:first-child td font{
    color: inherit!important;
}

.contact-section .details{
    display: flex;
    justify-content: space-between;
    gap:20px;
}
.contact-section .details .map{
    width: 50%;
}
.contact-section .details .map iframe{
    width: 100%;
}
.contact-section .details .info{
    width: 50%!important;
}
.ContInfo{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.cInfo {
    width: 50%;
}
.cInfo .title-text p:first-child{
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
}
.map{
    width: 50%;
}
.map h3{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}
.map iframe{
    width: 100%;
    height: 300px;
}

.dropdown-menu{
    border: none;
    background-color: var(--red);
    margin-top:0px!important;
    border-radius:0px;
    padding: 0px;
    border-bottom:1px solid var(--red);
}
.dropdown-menu li{
    color: var(--WColor);
    border-top: 1px solid #bc414d;
}
.dropdown-menu li:hover{
    background-color: var(--MColor)!important;
}
.dropdown-menu li a{
    color: inherit;
}
.dropdown-item{
    font-size: 12px!important;
    text-transform: uppercase;
    padding: 10px 20px;
}
.dropdown-item:hover{
    color: var(--red);
}

.dropdown:hover .dropdown-menu{
    display: block;
}
.ProductsItemsss{
    padding-bottom: 5%;
}
.ItemsBox{
    min-height: 320px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px #ddd;
    margin-bottom:10%;
}
.ItemsBox .image{
    width: 70%;
    margin: auto;
    height: 250px;
    overflow: hidden;
    padding: 10px;
}
.ItemsBox img{
    width:100%;
    height: 100%;
    background-color: #efefef;
    object-fit: cover;
}
.ItemsBox .title h2{
    padding:10px;
    font-size: 1rem;
    color: var(--SColor);
    text-align: center;
}






/* responsive design  */


@media (max-width: 1024px){
    .TopNav .container{
        width: 100%;
    }
    .TopItems li {
        font-size: 15px;
    }
}

@media (max-width: 768px){
    .contact-section .details{
        display: block;
    }
   .navbar  .container{
    justify-content: flex-end!important;
   }
    .TopItems .email{
        display: none;
    }
    .navbar-toggler{
        border-color: transparent;
    }
    .navbar-toggler:focus{
        box-shadow: none;
    }
    .SBox div{
        min-height: 370px;
    }
    .MainTrending{
        min-height: 75vh;
    }
    .ContInfo{
        display: block;
    }
    .map{
        width: 100%;
    }
    .cInfo {
        width: 100%;
    }
}
@media (max-width:425px){
    .container{
        padding:0rem  1rem!important;
    }
    .DN{
        display: none;
    }
    .AR{
        justify-content: flex-start;
    }
    .SearchForm {
       display: block;
     
       padding: 10px;
    }
    .SearchForm input{
        width:85%;
    }
    .TabImagess{
        display: none;
    }
    .ProductsImage{
        margin-bottom: 20px;
    }
    .SBox div{
        min-height: 240px;
    }
    .TTitle{
        font-size: 30px;
    }
    .CTitle{
        min-height:44px;
    }
    .CContent h1{
        font-size: 30px;
    }
    .CContent{
        margin-top: 30px;
    }
    .MainTrending{
        min-height: 70vh;
    }
}

@media (max-width:375px){
    .TopItems li{
        font-size: 12px;
    }
}